.Page {
    position: relative;
    min-height: 100vh;
    min-width: 70vw;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
    padding-bottom: 8px;
}
